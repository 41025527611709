<template>
    <div class="admins-grid m3">
      <accounting-settings :isOPS="true" ref="AccountingSettings"></accounting-settings>
    </div>
</template>

<script>
import AccountingSettings from "../../../settings/Accounting.vue";

export default {
  components: {
    AccountingSettings
  }, 
};
</script>

<style lang="scss">
  .admins-grid {
    margin-top: -10px;
  }
</style>
