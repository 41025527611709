<template>
  <div class="active_component">
    <component :is="tab.tabName"/>
  </div>
</template>

<script>
import LeaseConfigurationLibrary from './corporateSettings/leaseConfigurationLibrary/Index.vue'
import DelinquencyProcess from './corporateSettings/delinquencyProcess/Index.vue'
import DiscountLibrary from './corporateSettings/discountLibrary/Index.vue'
import Administrators from './corporateSettings/administrators/Index.vue'
import TaxProfiles from './corporateSettings/taxProfiles/Index.vue'
import DocumentLibrary from './corporateSettings/documentLibrary/Index.vue'
import corporate from './corporateSettings/Index.vue'
import Products from './corporateSettings/products/Index.vue'
import FeeLibrary from './corporateSettings/feeLibrary/Index.vue'
import property from './propertySettings/Index.vue'
import SpaceMixTenants from './propertySettings/SpaceMixTenants/Index.vue'
import { mapGetters, mapActions } from 'vuex';
import { Watch } from 'vue-property-decorator';

import AuctionSettings from './corporateSettings/auction/Index.vue'
import CoveragePlans from './corporateSettings/coveragePlans/Index.vue'
import TaxRates from './propertySettings/taxProfiles/Index.vue'
import Fees from './propertySettings/feesProfile/Index.vue'
import LeaseTemplates from './propertySettings/LeaseTemplates/Index.vue'
import OnboardingHome from './home/Index.vue';
import CoveragePlansProperty from './propertySettings/CoveragePlans/Index.vue'
import ProductsProperty from './propertySettings/Products/Index.vue'
import AccountingSettings from './corporateSettings/accountingSettings/Index.vue'
import MergeToHummingbird from './MergeToHummingbird/Index.vue'
import DelinquencyAutomation from './corporateSettings/delinquencyAutomation/Index.vue'
import AccessControl from './propertySettings/AccessControl/Index.vue'
import PaymentConfiguration from './propertySettings/PaymentConfiguration/Index.vue'

export default {
  components: {   
  Administrators,
  DiscountLibrary,
  corporate,
  LeaseConfigurationLibrary,
  Products,
  TaxProfiles,
  FeeLibrary,  
  DocumentLibrary,
  DelinquencyProcess,
  property,
  AuctionSettings,
  CoveragePlans,
  TaxRates,
  SpaceMixTenants,
  OnboardingHome,
  Fees,
  LeaseTemplates,
  CoveragePlansProperty,
  ProductsProperty,
  AccountingSettings,
  MergeToHummingbird,
  DelinquencyAutomation,
  AccessControl,
  PaymentConfiguration
  },
  data () {
    return {
      // tab : ""
    }
  },
   computed: {
     ...mapGetters({
       tabData:'onboardingTabsStore/getOnboardingTabs',
        tab:'onboardingTabsStore/getActiveTab'
     })
   },
    mounted(){
    
  },
  methods : {
    
  },
  watch: {
    tabData () {
      // console.log(this.tabData)
      // this.tab = this.tabData.activeTab
    },
  }
};
</script>

<style>
.active_component{
  margin-bottom: 50px;
}
</style>
